<template>
  <section
    class="presentation__agent animate__animated"
    :class="{
      presentation__rollup: rollup,
      presentation__rollback: rollback,
      animate__fadeIn: rollup,
    }"
  >
    <div class="presentation__agent-conteiner" v-if="!AgentData.agent">
      <q-skeleton type="circle" size="144px" />
      <q-skeleton type="text" height="30px" width="150px" />
      <q-skeleton type="text" height="30px" width="130px" />
      <q-skeleton type="text" height="30px" width="120px" />
      <q-skeleton type="text" height="30px" width="80px" />
    </div>
    <div class="presentation__agent-conteiner" v-else>
      <q-avatar size="144px">
        <q-inner-loading
          v-if="!AgentData.agent"
          style="z-index: 999"
          :showing="PStore.$state.agentInfoLoader"
        >
          <custom-loader />
        </q-inner-loading>
        <img
          v-else
          :src="AgentData.agent.avatar_url"
          alt="avatar"
          onerror="this.src='https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"
        />
      </q-avatar>
      <div class="agent-info">
        <h4 class="agent-info-name">
          {{ AgentData.agent.last_name + ' ' + AgentData.agent.first_name }}
        </h4>
        <p class="agent-info-work">{{ switchWorkAs(AgentData.agent.employment_status) }}</p>
        <span class="agent-info-stars">
          <q-icon size="16px" style="color: #fb923c" name="star" />
          <p>4,9 (40 отзывов)</p>
        </span>
        <span class="agent-info-city">
          <base-icon name="pin" size="16px" color="var(--slate-400)" />
          <p>{{ AgentData.agent.city }}</p>
        </span>
      </div>
      <div class="agent-chips" v-if="!rollup">
        <q-badge
          outline
          rounded
          class="agent-chips-chip"
          v-for="chip in AgentData.agent.directions"
          :label="switchDirections(chip)"
        />
      </div>
      <button class="agent_review-butt" @click="clickReview">
        {{
          $props.isOwner?.presentation && !$props.isOwner?.presentation.can_edit
            ? 'Оставить отзыв'
            : 'Настройки'
        }}
      </button>
      <div class="agent-about" v-if="!rollup">
        <p class="agent-about-title">О себе:</p>
        <p class="agent-about-about">
          {{ AgentData.agent.about_myself }}
        </p>
      </div>
      <agent-contacts :AgentData="AgentData" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { PopUpStore } from '~/store/popUp'
import { PresentationStore } from '~/store/PresentationStore'
import { switchWorkAs } from '~/helpers/agentWorkAsSwitch'
import { PresentationAll } from '~/interfaces/tsPresentation'

const props = defineProps({
  isScoped: {
    required: false,
    type: Boolean,
  },
  isOwner: {
    required: false,
    type: Object as () => PresentationAll,
  },
})

const router = useRouter()

const PStore = PresentationStore()
const PopUp = PopUpStore()

const rollup = computed(() => props.isScoped)
const rollback = ref(false)

const AgentData = computed(() => PStore.$state.tableObjsData)

const switchDirections = (
  type:
    | 'resale'
    | 'new_building'
    | 'country'
    | 'interregional'
    | 'investments'
    | 'mortgage'
    | 'refinancing'
    | 'legal_assistance',
) => {
  let value: string
  const map: Record<typeof type, string> = {
    resale: 'Вторичка',
    new_building: 'Новостройки',
    country: 'Загородная',
    interregional: 'Межрегиональная',
    investments: 'Инвестиции',
    mortgage: 'Ипотека',
    refinancing: 'Рефинансирование',
    legal_assistance: 'Юр. сопровождение',
  }
  value = map[type] ?? ''
  return value
}

const clickReview = () => {
  if (props.isOwner?.presentation && !props.isOwner?.presentation.can_edit) {
    PopUp.callPopUp('LeaveReview')
  } else {
    router.push('/profile')
  }
}

watch(rollup, () => {
  if (!rollup.value) {
    rollback.value = true
  } else {
    rollback.value = false
  }
})
</script>

<style scoped lang="scss">
.presentation {
  &__agent {
    width: 378px;
    height: fit-content;
    border-radius: 20px;
    background: var(--White, #fff);
    box-shadow: 0px 2px 8px 0px rgba(29, 29, 31, 0.1);
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    padding-top: 32px;
    position: absolute;
    z-index: 100;
    top: 48px;
    left: 48px;

    &-conteiner {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      align-items: center;
      gap: 16px;
      color: var(--slate-500, #7b798f);
      .agent {
        &-info {
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-items: center;
          cursor: default;
          &-name {
            color: var(--slate-800, #2c2933);
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            margin-bottom: 8px;
            margin-top: 0;
          }
          &-work {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            margin: 0;
          }
          &-stars {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-bottom: 16px;
          }
          &-city {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
        &-chips {
          display: flex;
          flex-wrap: wrap;
          gap: 6px;
          align-items: center;
          justify-content: center;
          margin-top: 8px;
          &-chip {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 4px;
            padding-bottom: 4px;
            color: var(--slate-500, #7b798f);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            border-radius: 90px;
            border: 1px solid var(--slate-200, #e9e9f3);
            cursor: default;
          }
        }
        &-about {
          display: grid;
          align-items: center;
          justify-items: flex-start;
          justify-self: flex-start;
          font-size: 14px;
          font-style: normal;
          line-height: 22px;
          margin-top: 8px;
          p {
            margin: 0;
            overflow-wrap: anywhere;
          }
          &-title {
            color: var(--slate-800, #2c2933);
            font-weight: 500;
          }
          &-about {
            color: var(--slate-500, #7b798f);
            font-weight: 400;
          }
        }
        &_review-butt {
          display: flex;
          margin-top: 20px;
          width: 330px;
          padding: 9px 32px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 12px;
          background: var(--slate-100, #f5f5fc);
          border: none;
          color: var(--violet-400, #6b50b2);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          cursor: pointer;
        }
      }
    }
  }
}
.presentation {
  &__rollback {
    animation: rollback 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  &__rollup {
    position: fixed;
    top: 48px;
  }
}
@keyframes rollback {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
